<template>
  <div class="mt-2 table-section">
    <div class="floating-select-field mb-3 d-flex align-items-center justify-content-between">
      <b-form-select
        id="myTable"
        class="form-control"
        v-model="selectedTable"
        @change="onChangeTable"
        @input="selectedTable = $event"
        :class="{
          'is-value-exist': selectedTable,
        }"
      >
        <b-form-select-option v-for="(table, index) in tableContent" :key="index" :value="table">
          {{ table.tableTitle }}<template v-if="table.detail">{{ ` (${table.detail})` }}</template>
        </b-form-select-option>
      </b-form-select>
      <label for="myTable">Select Table</label>
      <InfoButtonTogglePopover v-if="selectedTable && selectedTable.description" class="ml-2" :description="selectedTable.description" />
    </div>
    <template v-if="selectedTable">
      <p class="theme-font-bold mb-3" v-if="selectedTable.subTitle" id="widget-table-subtitle">
        {{ selectedTable.subTitle }}
      </p>
      <div v-if="selectedTable.tableData">
        <div class="b-table-sticky-header custom-scroll h-250">
          <table class="table table-striped" aria-labelledby="widget-table-subtitle">
            <thead>
              <tr>
                <th
                  scope="col"
                  :colspan="selectedTable.tableData.header.data.length === hIndex + 1 ? getColspan(hIndex + 1) : null"
                  v-for="(head, hIndex) in selectedTable.tableData.header.data"
                  :key="hIndex"
                >
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tr, trIndex) in selectedTable.tableData.body" :key="trIndex">
                <td :colspan="tr.data.length === tdIndex + 1 ? getColspan(tdIndex + 1) : null" v-for="(td, tdIndex) in tr.data" :key="tdIndex">
                  {{ td }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="no-table-data-wrapper" v-if="!selectedTable.tableData">
        <table class="table table-striped" aria-labelledby="widget-table-subtitle">
          <thead>
            <tr>
              <th scope="col" v-for="(head, ehIndex) in 3" :key="ehIndex"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tr, etrIndex) in 4" :key="etrIndex">
              <td v-for="(td, etdIndex) in 3" :key="etdIndex"></td>
            </tr>
          </tbody>
        </table>
        <p class="theme-font-bold no-table-data">No data available</p>
      </div>
    </template>
  </div>
</template>
<script>
import InfoButtonTogglePopover from '../common/InfoButtonTogglePopover.vue'
export default {
  name: 'Tables',
  components: {
    InfoButtonTogglePopover,
  },
  data() {
    return {
      selectedTable: null,
    }
  },
  props: ['tableContent'],
  computed: {
    getColspan() {
      return (length) => {
        const body = this.selectedTable.tableData.body.map((l) => l.data.length)

        body.push(this.selectedTable.tableData.header.data.length)
        return length < Math.max(...body) ? Math.max(...body) - length + 1 : null
      }
    },
  },
  mounted() {
    this.selectedTable = this.tableContent.length ? this.tableContent[0] : null
  },
  methods: {
    onChangeTable(table) {
      this.selectedTable = table
    },
  },
}
</script>
<style lang="scss" scoped>
.no-table-data-wrapper {
  position: relative;
  .table {
    opacity: 0.5;
  }
  .no-table-data {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    text-align: center;
    font-size: 20px;
  }
  .table th,
  .table td {
    padding: 1rem;
  }
}
</style>
